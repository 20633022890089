import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown, Dropdown,Container } from "react-bootstrap";
import { MdPinDrop } from 'react-icons/md';
import logo from '../logo.svg';
import imgApp from '../img/logoMini.svg'
// import { useDispatch, useSelector } from "react-redux";

// import { updateProfile } from "../redux/action/authAction";

function Hearder() {

  return (
    <Navbar className='bg-navbar shadow-sm fixed-top h-navbar'>
        <Container className=''>
            <Navbar.Brand href='/'>
              <div className='d-flex'>
              <img
                src={imgApp}
                style={{ width: "45px", height: "auto" ,borderRadius: "13%"}}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
                />
                <span className='text-white mt-2'><span className='fw-bold'>Orbit: </span> <small className='fw-light'>Driver Check in</small> </span>
                 </div>
                
            </Navbar.Brand>
        </Container>
    </Navbar>
  )
}

export default Hearder