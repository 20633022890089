import React from 'react'

import imgApp from '../../img/app_orbit.png'
import { Image } from 'react-bootstrap'
function IndexHome() {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-4 col-lg-6 mx-auto text-center">
          <Image
            src={imgApp}
            className="img-fluid  shadow"
            style={{ borderRadius: '13%' }}
          />
        </div>
        <div className="col-10 mx-auto text-center">
          <h2 className="fw-bold mt-3">Orbit: Driver Check in</h2>
          <h3 className="fw-bold mt-3">สวัสดี</h3>
          <p>
            โปรดเข้าสู่ระบบนี้ในช่องทางที่กำหนด หรือ จาก URL ต้นทาง
            หากยังพบปัญหาโปรดติดต่อเรา
          </p>
          <p className="small text-muted">Version 1.0.1</p>
        </div>
      </div>
    </div>
  )
}

export default IndexHome
