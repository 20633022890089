import React from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import axios from 'axios'
import Swal from 'sweetalert2'
// import { useParams } from 'react-router-dom';
import pincheckin from '../../img/check_in.svg'
import pincheckout from '../../img/check_out.svg'
import pincheckpoint from '../../img/check_point.svg'
import dateFormat from 'dateformat'
import moment from 'moment'
import { BsChevronDoubleLeft } from 'react-icons/bs'
import { GiPathDistance } from 'react-icons/gi'
import CryptoJS from 'crypto-js'

const loader = new Loader({
  apiKey: 'AIzaSyBW9_6Hh9oE1LSYMDnGioypZqTh0EDLCrQ'
})

function MapHistrory() {
  // const {id} = useParams();
  const [dataLocation, setLocation] = React.useState([])
  const [isLogin, setIsLogin] = React.useState(false)
  const [inToPoint, setInToPoint] = React.useState()
  const [outToPoint, setOutToPoint] = React.useState()
  const key = localStorage.getItem('key')
  React.useEffect(() => {
    loader.load().then(() => {
      initMap()
      /* global google */
    })
  }, [])

  let map
  let infoWindow

  function haversine_distance(mk1, mk2) {
    var R = 3958.8 // Radius of the Earth in miles
    var rlat1 = mk1.position.lat() * (Math.PI / 180) // Convert degrees to radians
    var rlat2 = mk2.position.lat() * (Math.PI / 180) // Convert degrees to radians
    var difflat = rlat2 - rlat1 // Radian difference (latitudes)
    var difflon = (mk2.position.lng() - mk1.position.lng()) * (Math.PI / 180) // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      )
    return d * 1.609344
  }

  function initMap() {
    infoWindow = new google.maps.InfoWindow({
      content: '',
      disableAutoPan: true
    })

    if (key != null) {
      // Decrypt
      const passphrase = 'secret:skydev2021@2P'
      const originalText = CryptoJS.AES.decrypt(key, passphrase).toString(
        CryptoJS.enc.Utf8
      )

      if (originalText) {
        axios({
          method: 'GET',
          url: `https://backorbit3.skyict.co.th/getHistoryChauffeurById`,
          headers: { authorization: 'skydev2021@2P' },
          params: {
            id: originalText
          }
        })
          .then((res) => {
            let obj = res.data

            if (obj.result.length > 0) {
              setIsLogin(true)
              map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(
                  obj.result[0].latitude_in,
                  obj.result[0].longitude_in
                ),
                zoom: 15
              })
              //

              setLocation(obj.result[0])

              PinInMap(obj.result[0])
            } else {
              Swal.fire({
                title: 'ไม่พบข้อมูล',
                text: 'ข้อมูลนี้ไม่มีรายการเวลาการเข้า-ออก',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'รับทราบ'
              })
            }
          })
          .catch((e) => {
            console.log('e', e)

            Swal.fire({
              title: 'พบปัญหา',
              text: 'มีบางอย่างผิดพลาดโปรดลองอีกครั้ง',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'รับทราบ'
            })
          })
      } else {
        window.location = '/error'
      }
    } else {
      window.location = '/error'
    }

    const PinInMap = (data) => {
      const icons = {
        checkin: {
          icon: pincheckin
        },
        checkout: {
          icon: pincheckout
        },
        checkpoint: {
          icon: pincheckpoint
        }
      }

      const features = [
        {
          position: new google.maps.LatLng(data.latitude_in, data.longitude_in),
          datetime: data.checkin_date,
          name: 'Check In',
          type: 'checkin'
        },
        {
          position: new google.maps.LatLng(
            data.latitude_out,
            data.longitude_out
          ),
          datetime: data.checkout_date,
          type: 'checkout',
          name: 'Check Out'
        },
        {
          position: new google.maps.LatLng(
            data.check_point_lat,
            data.check_point_long
          ),
          datetime: data.checkout_date,
          type: 'checkpoint',
          name: 'Check Point'
        }
      ]

      // Create markers.
      for (let i = 0; i < features.length; i++) {
        const marker = new google.maps.Marker({
          position: features[i].position,
          icon: icons[features[i].type].icon,
          map: map
        })

        marker.addListener('click', () => {
          infoWindow.setContent(
            `<p class='mb-1  h5 fn-default fw-bold'>${
              features[i].name
            }</p> <p class='mb-0 fn-default'>Time : ${dateFormat(
              features[i].datetime,
              'HH:MM'
            )}</p>`
          )
          infoWindow.open(map, marker)
        })
        //return marker
      }

      const checkPoint = {
        lat: parseFloat(data.check_point_lat),
        lng: parseFloat(data.check_point_long)
      }
      const checkIn = {
        lat: parseFloat(data.latitude_in),
        lng: parseFloat(data.longitude_in)
      }

      // The markers for The Dakota and The Frick Collection
      var mkPoint = new google.maps.Marker({ position: checkPoint })
      var mkCheckIn = new google.maps.Marker({ position: checkIn })
      var distance = haversine_distance(mkPoint, mkCheckIn)

      // console.log("Distance between markers: In" ,distance.toFixed(2) + " mi.")
      setInToPoint(distance.toFixed(2))

      if (data.latitude_in != null && data.latitude_out != null) {
        const checkOut = {
          lat: parseFloat(data.latitude_out),
          lng: parseFloat(data.longitude_out)
        }
        var mkCheckOut = new google.maps.Marker({ position: checkOut })

        // console.log("Distance between markers: out" ,haversine_distance(mkPoint, mkCheckOut).toFixed(2) + " mi.")
        setOutToPoint(haversine_distance(mkPoint, mkCheckOut).toFixed(2))
      }
    }
  }
  return (
    <div>
      <div id="map"></div>
      <div className="container ">
        <div className="row fixed-bottom">
          <div className="col-12 text-lg-center text-center ">
            <div className="card  border-0 shadow-lg">
              <div className="card-body pt-0 pb-0">
                <div className="row" style={{ height: '23vh' }}>
                  <div className="col-12 text-start text-lg-center my-auto">
                    <h5 className="fw-bold">
                      {moment(dataLocation.checkin_date).format(
                        'dddd Do MMMM YYYY'
                      )}
                    </h5>
                    <div className="row">
                      <div className="col-6 col-lg-4 mx-auto">
                        <p className="mb-0">
                          Clockin:{' '}
                          <span className="fw-bold text-success">
                            {dateFormat(dataLocation.checkin_date, 'HH:MM')}{' '}
                          </span>{' '}
                        </p>
                        <p className="mb-0">
                          Distance <GiPathDistance />{' '}
                          <span className="fw-bold">{inToPoint} km.</span>
                        </p>
                      </div>
                      <div className="col-6 col-lg-4 mx-auto">
                        {dataLocation.checkout_date && (
                          <>
                            <p className="mb-0">
                              Clockout:{' '}
                              <span className="fw-bold text-danger">
                                {dataLocation.checkout_date
                                  ? dateFormat(
                                      dataLocation.checkout_date,
                                      'HH:MM'
                                    )
                                  : 'No Data'}
                              </span>{' '}
                            </p>
                            <p className="mb-0">
                              Distance <GiPathDistance />{' '}
                              <span className="fw-bold">
                                {dataLocation.checkout_date
                                  ? outToPoint + ' km.'
                                  : 'No Data'}
                              </span>
                            </p>
                          </>
                        )}
                        {/* <p className='mb-0'>Clockout: <span className='fw-bold text-danger'>18.00</span>  </p>
                <p className='mb-0'>Distance <GiPathDistance/> <span className='fw-bold'>0.6 km.</span></p> */}
                      </div>
                    </div>

                    <button
                      className="btn btn-dark btn-sm mt-3 float-end"
                      onClick={() => {
                        window.location = '/history'
                      }}
                    >
                      <BsChevronDoubleLeft /> Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapHistrory
