import logo from './logo.svg';
// import check_in from './check_in.png';
// import check_in from './img/check_in.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import IndexHistory from './pages/history/IndexHistory';
import MapHistrory from './pages/history/MapHistrory';
import Hearder from './components/Hearder';
import IndexError from './pages/error/IndexError';
import IndexHome from './pages/Home/IndexHome';
import CheckLogin from './pages/CheckLogin';

function App() {
  return (
    <Router>
      <Hearder/>
      
      <div style={{marginTop: "8vh",marginBottom: "2rem"}}>
      {/* <img src={check_in} /> */}
      <Switch>
          <Route exact path="/">
           <IndexHome/>
          </Route>
          <Route  path="/history">
            <IndexHistory/>
          </Route>
          <Route path="/map/">
           <MapHistrory/>
          </Route>
          <Route path='/error'>
            <IndexError/>
          </Route>
          <Route path='/chk/:otp'>
            <CheckLogin/>
          </Route>
        </Switch>

      </div>
     
    </Router>
  );
}

export default App;
