import React from 'react'
import { useParams } from 'react-router-dom';


function CheckLogin() {
    const {otp} = useParams();
    React.useEffect(()=>{
        //localStorage.setItem('username',username)
        localStorage.setItem('otp',otp)
        setTimeout(()=>{
            window.location.replace("/history")
          },100)

    },[])
  return (
    <div className='preloader-web'></div>
  )
}

export default CheckLogin