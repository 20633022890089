import React from 'react'
import { FcBrokenLink } from 'react-icons/fc';
function IndexError() {
  return (
    <div className='container pt-5'>
      <div className='row'>
          <div className='col-10 mx-auto text-center'>
              <h1 style={{fontSize: "8rem"}}><FcBrokenLink/></h1>
              <h2 className='fw-bold'>การเชื่อมต่อไม่ถูกต้อง</h2>
              <p>โปรดเข้าสู่ระบบจากช่องทางที่กำหนด หรือ ตรวจสอบ URL อีกครั้ง หากยังพบปัญหาโปรดอย่าลังเลที่จะติดต่อเรา</p>
           
          </div>
        
      </div>
  </div>

  )
}

export default IndexError