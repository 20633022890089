import React from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import { Card, Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import dateFormat from 'dateformat'
import moment from 'moment'
import { BsChevronDoubleRight } from 'react-icons/bs'
import { RiUser3Fill } from 'react-icons/ri'
import CryptoJS from 'crypto-js'

function IndexHistory() {
  const [isLogin, setIsLogin] = React.useState(false)
  const [getProfile, setProfile] = React.useState([])

  const otp = localStorage.getItem('otp')

  React.useEffect(() => {
    getHistoryChauffeur()
  }, [])

  const getHistoryChauffeur = async () => {
    try {
      await axios({
        method: 'GET',
        url: `https://backorbit3.skyict.co.th/getHistoryChauffeur`,
        headers: { authorization: 'skydev2021@2P' },
        params: {
          otp: otp
        }
      })
        .then((res) => {
          let obj = res.data
          setIsLogin(true)

          if (obj.status) {
            GetDataLocationTime(obj.result.data)
            setProfile(obj.result.profile[0])
            //console.log("")
          } else {
            window.location.replace('/error')
          }
        })
        .catch((e) => {
          window.location.replace('/error')
        })
    } catch (error) {
      window.location.replace('/error')
    }
  }

  const [dataHistory, setHistory] = React.useState([])
  const [locatCheckIn, setCheckIn] = React.useState([])
  const [locatCheckOut, setCheckOut] = React.useState([])

  function CouthTimeTotal(min) {
    let hr = min / 60
    let minHr = parseInt(hr)
    let minAns = (min / 60 - parseInt(hr)) * 60

    return minHr + 'hr ' + Math.ceil(minAns) + 'min'
  }

  const GetDataLocationTime = async (history) => {
    var checkIn = []
    var checkOut = []

    for (var i = 0; i < history.length; i++) {
      const location_in = await axios({
        method: 'GET',
        url: 'https://maps.googleapis.com/maps/api/geocode/json?',
        params: {
          latlng: `${history[i].latitude_in},${history[i].longitude_in}`,
          key: 'AIzaSyBW9_6Hh9oE1LSYMDnGioypZqTh0EDLCrQ'
        }
      })

      checkIn.push(location_in.data.results[1].formatted_address)

      if (history[i].latitude_out != null) {
        const location_out = await axios({
          method: 'GET',
          url: 'https://maps.googleapis.com/maps/api/geocode/json?',
          params: {
            latlng: `${history[i].latitude_out},${history[i].longitude_out}`,
            key: 'AIzaSyBW9_6Hh9oE1LSYMDnGioypZqTh0EDLCrQ'
          }
        })
        //geocodeLatLng(history[i].latitude_in,history[i].longitude_in,'out')

        checkOut.push(location_out.data.results[1].formatted_address)
      }
    }

    if (checkIn.length == history.length) {
      setHistory(history)
      setCheckIn(checkIn)
      setCheckOut(checkOut)
      //   setIsLoading(false);
    }
  }

  const nextMoreInformation = (inputText) => {
    const passphrase = 'secret:skydev2021@2P'

    var ciphertext = CryptoJS.AES.encrypt(
      inputText.toString(),
      passphrase
    ).toString()

    localStorage.setItem('key', ciphertext)
    window.open('/map', '_self')
  }

  return (
    <Container>
      {getProfile.folder_img && (
        <Row>
          <Col lg={6}>
            <div className="shadow-sm border-0 mt-3 card">
              <div className="card-body">
                <div className="d-flex">
                  <div className="">
                    <p className="mb-0 fw-bold">
                      <RiUser3Fill /> {getProfile.name_en}{' '}
                    </p>
                    <p className="mb-0 small">ID: {getProfile.code}</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        {dataHistory.map((d, index) => {
          return (
            <Col lg={6} sm={12} className=" mt-3" key={index}>
              <Card className="shadow border-0">
                <Card.Body>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="fw-bold mb-3 ">
                        {moment(d.checkin_date).format('dddd Do MMMM YYYY')}
                      </h5>

                      <p className="mb-0">
                        <span className="fw-bold">Clockin:</span>{' '}
                        {dateFormat(d.checkin_date, 'HH:MM')}
                      </p>
                      <p>
                        {' '}
                        <span className="fw-bold">Location:</span>{' '}
                        {locatCheckIn[index]}
                      </p>

                      {d.checkout_date && (
                        <>
                          <p className="mb-0 text-secondary">
                            {moment(d.checkout_date).format(
                              'dddd Do MMMM YYYY'
                            )}
                          </p>

                          <p className="mb-0">
                            <span className="fw-bold">Clockout:</span>{' '}
                            {dateFormat(d.checkout_date, 'HH:MM')}
                          </p>
                          <p>
                            <span className="fw-bold">Location:</span>{' '}
                            {locatCheckOut[index]}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="col-12">
                      <h5>
                        <span className="fw-bold">Total Hours:</span>{' '}
                        {CouthTimeTotal(d.minute)}{' '}
                      </h5>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-orbit"
                          onClick={() => nextMoreInformation(d.id)}
                        >
                          More Information <BsChevronDoubleRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default IndexHistory
